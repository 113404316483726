import React from "react";

// Library import
import { Container } from "react-bootstrap";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Image import
import Image from "react-bootstrap/Image";

// Local css import
import "../../css/learn-page-jobseeker/offer-section.css";

// Carousel responsive options
const options = {
    responsive: {
        0: {
            items: 1,  
            
        },
        850: {
            items: 2,      
        },
        1400: {
            items: 3,

        }
    },
    nav: true,
    navText: ["<div class='prev-btn'>‹</div>", "<div class='next-btn'>›</div>"],
};

const OfferSection = () => {
  return (
    <div>
      <Container className="offer-section-js">
        {/* Offer header */}
        <div className="offer-header">
          <h2>What We Offer</h2>
          <p>Features provided by BeyondHire for Job Seekers</p>
        </div>

        {/* Offer cards and carousel */}
        <OwlCarousel className="owl-theme" {...options}>
          <div className="offercard-offercard">
            <Image src="/images/offer-card-img1.png" alt="cardimg3863" className="offercard-cardimg" />
            <div className="offercard-contentsection">
              <div className="offercard-icon">
                <div className="offercard-vector">
                  <Image src="/images/offer-card-icon.svg" alt="Vector3863" />
                </div>
              </div>
              <div className="offercard-textsection">
                <span className="offercard-text">
                  <span>Find Jobs</span>
                </span>
                <span className="offercard-text2">
                  <span>View all the details of the payment of your job.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="offercard-offercard">
            <Image src="/images/offer-card-img2.png" alt="cardimg3863" className="offercard-cardimg" />
            <div className="offercard-contentsection">
              <div className="offercard-icon offer-i-2">
                <div className="offercard-vector">
                  <Image src="/images/offer-card-icon2.png" alt="Vector3863" />
                </div>
              </div>
              <div className="offercard-textsection">
                <span className="offercard-text">
                  <span>Find Jobs</span>
                </span>
                <span className="offercard-text2">
                  <span>View all the details of the payment of your job.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="offercard-offercard">
            <Image src="/images/offer-card-img3.png" alt="cardimg3863" className="offercard-cardimg" />
            <div className="offercard-contentsection">
              <div className="offercard-icon offer-i-3">
                <div className="offercard-vector">
                  <Image src="/images/offer-card-icon3.png" alt="Vector3863" />
                </div>
              </div>
              <div className="offercard-textsection">
                <span className="offercard-text">
                  <span>Find Jobs</span>
                </span>
                <span className="offercard-text2">
                  <span>View all the details of the payment of your job.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="offercard-offercard">
            <Image src="/images/offer-card-img1.png" alt="cardimg3863" className="offercard-cardimg" />
            <div className="offercard-contentsection">
              <div className="offercard-icon">
                <div className="offercard-vector">
                  <Image src="/images/offer-card-icon.svg" alt="Vector3863" />
                </div>
              </div>
              <div className="offercard-textsection">
                <span className="offercard-text">
                  <span>Find Jobs</span>
                </span>
                <span className="offercard-text2">
                  <span>View all the details of the payment of your job.</span>
                </span>
              </div>
            </div>
          </div>
          <div className="offercard-offercard">
            <Image src="/images/offer-card-img2.png" alt="cardimg3863" className="offercard-cardimg" />
            <div className="offercard-contentsection">
              <div className="offercard-icon">
                <div className="offercard-vector">
                  <Image src="/images/offer-card-icon.svg" alt="Vector3863" />
                </div>
              </div>
              <div className="offercard-textsection">
                <span className="offercard-text">
                  <span>Find Jobs</span>
                </span>
                <span className="offercard-text2">
                  <span>View all the details of the payment of your job.</span>
                </span>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </Container>
    </div>
  );
};

export default OfferSection;
