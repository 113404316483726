import React from "react";

// Library import
import { Container } from "react-bootstrap";
import Button from 'react-bootstrap/Button';

// Image import
import Image from "react-bootstrap/Image";

// Local css import
import "../../css/learn-page-jobseeker/analytics-section.css";


const AnalyticsSection = () => {
  return (
    <div>
        <Container className="analytics-section-container">
            {/* Analytics header */}
            <h2>Analytics for your ease</h2>
        </Container>
            {/* Analytics list */}

            {/* Analytics Demo 1 */}
            <div className="analytics-demo-1">
                <Container>
                    <div className="row justify-content-md-center align-items-center analytics-demo-1-row" >
                        <div className="col-lg-4 text-section">
                            <h3>Career Heatmaps</h3>
                            <ul>
                                <li>Overview of available jobs</li>
                                <li>View openings and applicants</li>
                                <li>Search through interactive map</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 image-section">
                            <Image src="/images/career-heatmaps-dummy.svg"/>
                        </div>
                    </div>
                </Container>
            </div>

            {/* Analytics Demo 2 */}
                    <Container className="analytics-demo-2">
                        <div className="row justify-content-md-center align-items-center analytics-demo-2-row 
                            flex-column-reverse 
                            flex-lg-row" 
                        >                    
                            <div className="col-lg-7 image-section">
                                <Image src="/images/job-stats-dummy.svg"/>
                            </div>
                            <div className="col-lg-4 text-section">
                                <h3>Job Stats</h3>
                                <ul>
                                    <li>Summarized view of jobs</li>
                                    <li>Stats filtered according to time</li>
                                    <li>Track interviews</li>
                                </ul>
                            </div>
                        </div>
                    </Container>

             {/* Analytics Demo 3 */}
             <div className="analytics-demo-3">
                <Container>
                    <div className="row justify-content-md-center align-items-center analytics-demo-3-row" >
                        <div className="col-lg-4 text-section">
                            <h3>Roadmaps</h3>
                            <ul>
                                <li>Overview of available jobs</li>
                                <li>Achievement view</li>
                                <li>Track Deadlines</li>
                            </ul>
                        </div>
                        <div className="col-lg-7 image-section">
                            <Image src="/images/roadmaps-dummy.svg"/>
                        </div>
                    </div>
                </Container>
            </div>

            {/* Get started section */}
            <Container className="get-started-js">
                <div className="row justify-content-md-center get-started-js-row">
                    <div className="col-lg-7 text-section">
                        <h3>Get Started as a Job Seeker on BeyondHire</h3>
                        <Button size="lg">Sign Up</Button>
                    </div>
                    <div className="col-lg-3 image-section">
                        <Image src="/images/get-started-jobseeker.png"/>
                    </div>
                </div>
            </Container>
    </div>
  )
}

export default AnalyticsSection