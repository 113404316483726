import React from 'react'

// Library import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/partners.css";

// Carousel responsive options
const options = {
  responsive: {
      0: {
          items: 2,  
          
      },
      850: {
          items: 3,      
      },
      1400: {
          items: 4,

      }
  },
  nav: true,
  navText: ["<div class='prev-btn'>‹</div>", "<div class='next-btn'>›</div>"],
};

const Partners = () => {
  return (
    <div className='partner-div'>
        <h2>Our Partners</h2>
        <OwlCarousel className="owl-theme" {...options}>
          <div className="partners-images">
            <Image src="images/company-1.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-2.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-3.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-4.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-5.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-6.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-7.svg" />
          </div>
          <div className="partners-images">
            <Image src="images/company-8.svg" />
          </div>
        </OwlCarousel>
    </div>
  )
}

export default Partners