import React from "react";

// Bootstrap import
import { Container } from "react-bootstrap";

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/userType.css";


const UserType = () => {
  return (
    <Container className="main-container">
      {/* Beyond hire message */}
      <h1 className="motto">
        See why <span>BeyondHire</span> is your one-stop-shop to move your
        career <span>above</span> and <span>beyond</span>
      </h1>

      {/* User types */}
      <div className="users-container">
        <div className="row image-row">

          {/* Job Seeker user */}
          <div className="col-md-4 user-card">
            <div className="user-image">
            </div>
            <p>Job Seeker</p>
          </div>

          {/* Recruiter user */}
          <div className="col-md-4 user-card">
            <div className="user-image">              
            </div>
            <p>Recruiter</p>
          </div>

          {/* Student user */}
          <div className="col-md-4 user-card">
            <div className="user-image">           
            </div>
            <p>Student</p>
          </div>
        </div>

        {/* User description section */}
        <div className="row justify-content-md-center description-row">
          <div className="col-md-5 description-section">
            <h2>Job Seeker</h2>
            <p>Explore great opportunities from the various employers offered from BeyondHire. We help users 
              find the jobs best suited for them. BeyondHire offers features for searching for jobs based on 
              your preferences, salaries, and skillset.</p>
          </div>
          <div className="col-lg-6 image-section">
            <Image src="images/Job seeker learn.png" />
          </div>
        </div>
      </div>

    </Container>
  );
};

export default UserType;
