import React from 'react'

// Library import
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/support/support-hero.css";

const SupportHero = () => {
  return (
    <div>
        <Container className='support-hero'>
            <div className="row justify-content-md-center support-hero-row">
                <div className="col-lg-7 image-section">
                    <Image src='images/support-hero-bg.svg'></Image>
                </div>
                <div className="col-lg-5 text-section">
                    <h1>How can we help you?</h1>
                    <div className="search">
                          <FontAwesomeIcon icon={faSearch} className="search-icon"></FontAwesomeIcon>
                          <input type="text" class="form-control" placeholder="Have a question? Ask now" />
                    </div>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default SupportHero