import React from 'react'

// Library import
import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button'

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/business-about/business-hero.css";

const BusinessHero = () => {
  return (
    <div className='business-hero'>
        
        <Container>
        <div className="row justify-content-md-center business-about-content">
                <div className="col-xl-5 text-section">
                    <h1>Find Your Perfect Match and Beyond</h1>
                    <p>We curate the best digital jobs for those who are  
                        looking to start their career</p>
                        <Button variant='start-posting'>
                            <Image src='images/start-posting-icon.svg'/> Start Posting
                        </Button>
                </div>
                <div className="col-xl-6 image-section">
                    <Image  src="images/business-hero-img.svg"/>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default BusinessHero