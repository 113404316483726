import React from 'react'

// Library import
import { Container } from 'react-bootstrap';

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/newConnections.css";

const NewConnections = () => {
  return (
    <Container className="main-container">
      <div className="row justify-content-md-center description-row">          
        <div className="col-lg-6 image-section">
          <Image class="w-100" src="images/newconnectionsimage.png" />
        </div>
        <div className="col-lg-5 description-section">
          <h2>Explore people and create new connections</h2>
          <p>BeyondHire allows you to find new people whether it be recruiters,
          job seekers, or students. Get to know people that you share simliar 
          interests and goals with.</p>
          <div className="app-stores">
            <Image className='app-logo' src= "images/google-play.png" />
            <Image className='app-logo' src= "images/app-store.png" />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default NewConnections