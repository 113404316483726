import React from "react";

// Library import
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

// Image import
import Image from "react-bootstrap/Image";

// Local css import
import "../../css/support/articles.css";

const Articles = () => {
  return (
    <div>
       <Container className="articles-container">
            {/* Articles header */}
            <div className="articles-header">
                <h2>Articles</h2>
            </div>
            <div className="row justify-content-md-center articles-row">
                {/* Left article section */}
                <div className="col-lg-5 article-col">
                    <div className="article-item">
                        <h4>Finding the ideal Job</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit.</p>
                    </div>
                    <div className="article-item">
                        <h4>Top recruiting tips</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit.</p>
                    </div>
                    <div className="article-item">
                        <h4>How to attract recruiters</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit.</p>
                    </div>
                </div>

                {/* Article illustration section */}
                <div className="col-lg-2 image-col">
                    <Image src="images/support-articles.svg"/>
                </div>

                {/* Right article section */}
                <div className="col-lg-5 article-col">
                <div className="article-item">
                        <h4>Job recruiting applications</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit.</p>
                    </div>
                    <div className="article-item">
                        <h4>Marketing</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit</p>
                    </div>
                    <div className="article-item">
                        <h4>Reveal your personality</h4>
                        <p>Lorem ipsum dolor sit amet, consecteutr adnios elit.</p>
                    </div>
                </div>
            </div>
        </Container> 
    </div>
  )
}

export default Articles