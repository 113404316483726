import React from "react";

// Library import
import { Container } from "react-bootstrap";

// Image import 
import Image from 'react-bootstrap/Image'

// Local css import
import "../../css/learn-page-jobseeker/learn-hero.css";

const LearnHero = () => {
  return (
    <div className='learn-hero-div'>
        <Container>
            <div className="row justify-content-md-center learn-hero-content">
            
                <div className="col-lg-5 text-section">
                    <h1>Find the right job for you as a <span>Job Seeker</span></h1>
                    <p>Explore great opportunities from the various jobs
                         offered in BeyondHire. We help users find the jobs best 
                         suited for them. </p>
                </div>
                <div className="col-lg-6 image-section">
                    <Image  src="images/Job seeker learn.png"/>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default LearnHero