import React from 'react'
import BusinessHero from './BusinessHero'
import OrganizationType from './OrganizationType'
import BusinessFeaturesCarousel from './BusinessFeaturesCarousel'
import BusinessFeatures from './BusinessFeatures'

const BusinessIndex = () => {
  return (
    <div>
        <BusinessHero />
        <OrganizationType />
        <BusinessFeaturesCarousel />
        <BusinessFeatures />
    </div>
  )
}

export default BusinessIndex