import React from 'react'

// Library import
import { Container } from 'react-bootstrap';

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/business-about/organization-type.css";


const OrganizationType = () => {
  return (
    <div className='container-fluid organization-type'>
        {/* Get started Header */}
        <h2>How You Can Get Started in <span>BeyondHire</span></h2>

        {/* Selection Options */}
        <div className="row justify-content-md-center selection-section">
            <div className="col-lg-4 select-col">
                <div className="org-option">
                    <Image src='images/business-select-img.png'/>
                </div>
                <div className="text-section">
                    <h3>BUSINESS</h3>
                    <p>(Small, Medium, Large)</p>
                </div>
            </div>
            <div className="col-lg-4 select-col">
                <div className="org-option">
                    <Image src='images/school-select-img.png'/>
                </div>
                <div className="text-section">
                    <h3>SCHOOL</h3>
                    <p>(2-year, 4-year, graduate)</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OrganizationType