import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { NavLink, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

const SecurityOption = ({formData, setFormData, setPage}) => {
  return (
    <div>
      <Container className="mt-4 mb-4 p-4 form-div shadow">
        
      <Image src="images/get_started_icon.png" 
                            alt="" 
                            width="26" 
                            height="30"
                            className="align-top me-2" />

      <span className="heading-txt">Security Option</span>

        <hr/>
        <Row className="gx-5 mt-4 mb-4 d-flex justify-content-evenly">
            <Col sm={4}>
                <div className="shadow p-4 text-center">
                    <span className="heading-txt">Two Factor Authentication</span>
                </div>
            </Col>

            <Col sm={4}>
                <div className="shadow p-4 text-center" >
                    <span className="heading-txt">Facial Recognition</span>
                </div>
            </Col>

        </Row>
        </Container>

        <Container>
        <Col md={12} className="text-end">
            <Button 
            type="submit" 
            variant="primary"
            onClick={()=>{
                setPage( (curPage) => curPage+1)
            }}
                >Continue </Button>
         </Col>
         </Container>
      
    </div>

  )
}

export default SecurityOption
