import React, { useEffect, useState } from 'react';
import LoginInfo from './LoginInfo';
import ThemeSelect from './ThemeSelect';
import SecurityOption from './SecurityOption';
import Congratulation from './Congratulation';

const SignUp = () => {
  const [page,setPage] = useState(0);

  const [formData,setFormData] = useState({
    firstName:"",
    lastName:"",
    email:"",
    password:"",
    repassword:"",
    theme:2
  });

const FormTitles = ["Login Info","Security Option","Theme Selection","Congratulation"];

  const PageDisplay = () => {
    switch (page) {
      case 0:
            return <LoginInfo formData={formData} setFormData={setFormData} setPage={setPage}/>
      case 1:
          return <SecurityOption formData={formData} setFormData={setFormData} setPage={setPage}/>
      case 2:
          return <ThemeSelect formData={formData} setFormData={setFormData} setPage={setPage}/>
      case 3:
          return <Congratulation formData={formData} setFormData={setFormData} setPage={setPage}/>
    }  
    
  }

    return(
      <>
        {PageDisplay()}
         <button 
          disabled={page==0}
          onClick={()=>{setPage((cur)=>cur-1);}}>Prev</button>
          <button 
          disabled={page==FormTitles.length-1}
          onClick={()=>{setPage((cur)=>cur+1);}}>Next</button>

     </>
    )

}
export default SignUp;