import React from 'react'
import SupportHero from './SupportHero'
import FAQ from './FAQ'
import Articles from './Articles'
import Contact from './Contact'

const SupportIndex = () => {
  return (
    <div>
        <SupportHero />
        <FAQ />
        <Articles />
        <Contact />
    </div>
  )
}

export default SupportIndex