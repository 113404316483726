import React from 'react'
import { Container } from 'react-bootstrap'
import Col from 'react-bootstrap/Col';

const Congratulation = ({formData, setFormData, setPage}) => {
  return (
    
    <div>

{console.log(formData)}

      <Container className="mt-4 mb-4 p-4 form-div shadow">

        <Col sm={12} className="text-center">

        <span className="heading-txt">Congratulation [ {formData.firstName} {formData.lastName} ] !</span>

        <p>You have successfully created your account. <br />
        It’s time to move your career above and beyond <b>[Job Seeker]!</b><br />
        It’s time to find you the right candidates <b>[Recruiter]!</b><br />
        It’s time for you to find new opportunities <b>[Student]!</b>

        </p>

         </Col>

      </Container>
    </div>
  )
}

export default Congratulation
