import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { NavLink, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

const ThemeSelect = ({formData, setFormData, setPage}) =>{

    return(

        <div>
        <Container className="mt-4 mb-4 p-4 form-div shadow">

        <Image src="images/get_started_icon.png" 
                                    alt="" 
                                    width="26" 
                                    height="30"
                                    className="align-top me-2" />

        <span className="heading-txt">Chose Your Theme</span>
     
            <Row className="mt-4 mb-4 d-flex justify-content-evenly">
               
               <Col sm={3}>

                <Col sm={12}>

                   <Form.Check
                        inline
                        type="radio" 
                        id="theme-light" 
                    >
                        <Form.Check.Input 
                        type="radio" 
                        name="theme" 
                        value={2}
                        checked={formData.theme == 2}
                        onChange={(event) => setFormData({...formData,theme:event.target.value})}
                        
                        />
                    
                    <Form.Check.Label htmlFor="theme-light">
                        Light Mode<br></br>
                         <Image src="images/theme1.jpg" 
                                    alt="" 
                                    className="radio-img mt-3 border" />
                    </Form.Check.Label>

                    </Form.Check>


                </Col>
              

              </Col>

              <Col sm={3}>

                <Col sm={12}>

                <Form.Check
                        inline
                        type="radio" 
                        id="theme-dark" 
                    >
                        <Form.Check.Input 
                        type="radio" 
                        name="theme" 
                        value={3}
                        checked={formData.theme == 3}
                        onChange={(event) => setFormData({...formData,theme:event.target.value})}
                        
                        />
                    
                    <Form.Check.Label htmlFor="theme-dark">
                        Dark Mode<br></br>
                        <Image src="images/theme2.jpg" 
                                    alt="" 
                                    className="radio-img mt-3" />
                    </Form.Check.Label>

                    </Form.Check>
                    {/* <Form.Check

                            inline
                            label="Dark Mode"
                            name="theme"
                            type="radio"
                            checked={formData.theme == 3}
                            value={3}
                            onChange={(event) => setFormData({...formData,theme:event.target.value})}
                        /> */}
                </Col>

                </Col>
  

            </Row>

      </Container>

      <Container>
        <Col md={12} className="text-end">
            <Button 
            type="submit" 
            variant="primary"
            onClick={()=>{
                setPage( (curPage) => curPage+1)
            }}
                >Continue </Button>
         </Col>
         </Container>

      </div>
    )
}

export default ThemeSelect