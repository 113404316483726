import React from 'react'
import LearnHero from './LearnHero'
import OfferSection from './OfferSection'
import InterviewSection from './InterviewSection'
import AnalyticsSection from './AnalyticsSection'

export const Index = () => {
  return (
    <div>
        <LearnHero />
        <OfferSection />
        <InterviewSection />
        <AnalyticsSection />
    </div>
  )
}

export default Index;
