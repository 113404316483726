import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import { NavLink, Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';

const LoginInfo = ({formData, setFormData, setPage}) => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
      
      event.preventDefault();
      
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        setPage((curPage)=>curPage+1);
      }
     
    };

    return(
<>
      <Container className="mt-4 mb-4">
        
        <Row className="shadow">
        
            <Col sm={6} className="form-div p-5">
                
            <Image src="images/get_started_icon.png" 
                            alt="" 
                            width="26" 
                            height="30"
                            className="align-top me-2" />

                <span className="heading-txt">Get Started</span>
               
                <p className="mb-4">Already have a account <a href="#" className="form-link-txt">Log In</a></p>
               
                <Form className="g-4" noValidate validated={validated} onSubmit={handleSubmit}>

                    <Row className="mb-3">
               
                       <Col sm={6}>
                        <Form.Group controlId="firstName">
                          
                            <Form.Label>First Name</Form.Label>

                            <Form.Control 
                            required
                            type="text" 
                            value={formData.firstName}
                            onChange={(event) => setFormData({...formData,firstName:event.target.value})}
                            placeholder="First Name" />

                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Please choose a First Name.
                            </Form.Control.Feedback>
                          
                        </Form.Group>
                      </Col>

                      <Col sm={6}>
                        <Form.Group controlId="lastName">
                          
                            <Form.Label>Last Name</Form.Label>

                            <Form.Control 
                            required
                            type="text" 
                            value={formData.lastName}
                            onChange={(event) => setFormData({...formData,lastName:event.target.value})}
                            placeholder="Last Name" />

                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Please choose a Last Name.
                            </Form.Control.Feedback>
                          
                        </Form.Group>
                      </Col>
             
                    </Row>
        
                   <Col md={12}>
                    <Form.Group className="mb-3" controlId="emailPhone">
                      <Form.Label>Email or Phone Number</Form.Label>

                      <Form.Control 
                        type="text" 
                        value={formData.email}
                        onChange={(event) => setFormData({...formData,email:event.target.value})}
                        required
                        placeholder="Email or Phone Number" />

                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Please choose a Email/Phone.
                            </Form.Control.Feedback>

                    </Form.Group>
                  </Col>


                    <Col md={12}>
                    <Form.Group className="mb-3" controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control required type="password" />

                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Please choose a Password.
                       </Form.Control.Feedback>
                    </Form.Group>  
                    </Col>
              

                    <Col md={12}>
                    <Form.Group className="mb-3" controlId="rePassword">
                        <Form.Label>Re-Type Password</Form.Label>
                        <Form.Control required type="password" />

                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Please Retype Password.
                        </Form.Control.Feedback>

                    </Form.Group>
                    </Col>
                    
                    <p>By Clicking Sign up, you have agreed to our <span className="form-link-txt">terms</span>, <span className="form-link-txt">privacy</span> and <span className="form-link-txt">data policy</span></p>
                 
                    <Col md={12}>
                      <Button type="submit" variant="primary">Sign in</Button>
                    </Col>

                  </Form>
            
            </Col> 
           

            <div className="col-sm-6 form-div p-5 border-start border-2">
  
                   
            <span className="heading-txt">Add Professional Photo</span>


                <div className="mt-5 mb-5 pt-4">

                    <form method="post" action="#" id="#">
           
                        <div className="form-group files">
                          <input type="file" className="form-control" multiple="" />
                        </div>
                        
                      
                    </form>
                </div>

                <Col sm={12} className="mt-5">
                    <Row className="g-2">
                    <Col sm={6} className="p-2 shadow rounded-3">

                    <Image src="images/google_icon.png" 
                            alt="" 
                            className="me-2" />
                    Continue with Google

                    </Col>
                    <Col sm={6} className="facebook p-2 shadow rounded-3">

                    <Image src="images/facebook_icon.png" 
                            alt="" 
                            className="me-2" />
                    Continue with Facebook

                    </Col>
                    </Row>
                </Col>
     
            </div>           

        </Row>          

      </Container> 
</>
    )

}
export default LoginInfo;