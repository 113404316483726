import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import axios from 'axios';
//import  { useEffect, useState } from 'react';
import { NavLink, Link,Outlet } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Header = () => {
    return (

        <>
        
        <div className="top-header">

        <Container>

            <Row className="align-items-center p-3">

                <Col sm={2}>

                <Navbar.Brand href="#">
                <Image src="images/logo.png" 
                            alt="" 
                            width="140" 
                            height="40"
                            className="d-inline-block align-top" />


                </Navbar.Brand>



                </Col>
                

                <Col sm={10} className="text-end d-none d-sm-block">
                       Moving your career above and beyond
                </Col>

             
                <Col sm={10} className="d-block d-sm-none">
                    Moving your career above and beyond
                </Col>
               

            </Row> 
           

        </Container> 
       
      </div>

    <Outlet />

    </>
            
        )
}
export default Header;