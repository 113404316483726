import React from "react";

// Library import
import { Container } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button'

// Image import
import Image from "react-bootstrap/Image";

// Local css import
import "../../css/support/contact.css";

const Contact = () => {
  return (
    <div>
        <Container className="contact-us-container">
            {/* Contact Header */}
            <div className="contact-header">
                <h2>Need help with something else?</h2>
            </div> 

            {/* Contact details section*/}
            <div className="row justify-content-md-center contact-row">
                <div className="col-lg-4">
                    <div className="info-section">
                        <Image src="images/contact-icon1.svg"/>

                        <div className="text-section">
                            <div className="contact-option">
                                Give us a Call
                            </div>
                            <div className="contact-detail">
                                777-777-777777
                            </div>
                        </div>
                    </div>
                    <div className="info-section">
                        <Image src="images/contact-icon2.svg"/>

                        <div className="text-section">
                            <div className="contact-option">
                                Chat assisstant
                            </div>
                            <div className="contact-detail">
                                Live chat bot
                            </div>
                        </div>
                    </div>
                    <div className="info-section">
                        <Image src="images/contact-icon3.svg"/>

                        <div className="text-section">
                            <div className="contact-option">
                                Email us
                            </div>
                            <div className="contact-detail">
                                beyondhire@gmail.com
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 image-section">
                    <Image src="images/contact-us-img.svg"/>
                </div>
            </div>
             
             {/* Send message section */}
             <div className="row justify-content-md-center send-msg-row">
                <div className="col-lg-8 send-msg-section">
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="msg-header">Send us a message</Form.Label>
                            <Form.Control as="textarea" rows={6} placeholder="Write a message to us"/>
                        </Form.Group>
                        <Button className="msg-submit-btn">Submit</Button>
                    </Form>
                </div>
             </div>
        </Container>
        
    </div>
  )
}

export default Contact