import React from 'react'

// Bootstrap import
import "bootstrap/dist/css/bootstrap.min.css";

// Image import
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/video.css";


const Video = () => {
  return (
    <div className='main-div'>
        <h2> Want to know how <span>BeyondHire</span> works? </h2>
        <div className="video-container">
            <Image src="images/video.png"  />
        </div>
    </div>
  )
}

export default Video