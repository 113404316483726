import React from 'react'

// Library import
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/testimonialcard.css";

// Carousel responsive options
const options = {
    responsive: {
        0: {
            items: 1,  
            
        },
        850: {
            items: 2,      
        },
        1400: {
            items: 3,

        }
    },
    nav: true,
    navText: ["<div class='prev-btn'>‹</div>", "<div class='next-btn'>›</div>"],
};

const Testimonials = () => {
  return (
    <div className="main-wrapper container-lg testimonial-wrapper">
        {/* Testimonial header */}
        <h2>What people are saying about <span>BeyondHire</span></h2>
        {/* Carousel for testimonials */}
        <OwlCarousel className="owl-theme" {...options}>
            <div className="testimonialcard-container ">
                    <div className="testimonialcard-testimonialcard ">
                        <div className="testimonialcard-innercard">
                        <div className="testimonialcard-usersection">
                            <div className="testimonialcard-userinfo">
                                <div className="testimonialcard-user-avatar">
                                    <Image src="images/user-avatar.png" />
                                </div>
                            <div className="testimonialcard-username">
                                <span className="testimonialcard-text">
                                <span>Marge Simpson</span>
                                </span>
                                <span className="testimonialcard-text2">
                                <span>Backend Developer</span>
                                </span>
                            </div>
                            </div>
                            <div className="testimonialcard-userreview">
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg" />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-empty.svg"  />
                                </div>
                            </div>
                        </div>
                        <div className="testimonialcard-reviewsection">
                            <span className="testimonialcard-text4">
                            <span>
                                Very good experience using the services from BeyondHire
                            </span>
                            </span>
                            <span className="testimonialcard-text6">
                            <span>
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat aute irure sint
                                amet occaecat cupidatat non proidents
                            </span>
                            </span>
                        </div>
                        </div>
                    </div>
            </div>   
            <div className="testimonialcard-container ">
                    <div className="testimonialcard-testimonialcard ">
                        <div className="testimonialcard-innercard">
                        <div className="testimonialcard-usersection">
                            <div className="testimonialcard-userinfo">
                                <div className="testimonialcard-user-avatar">
                                    <Image src="images/user-avatar.png" />
                                </div>
                            <div className="testimonialcard-username">
                                <span className="testimonialcard-text">
                                <span>Marge Simpson</span>
                                </span>
                                <span className="testimonialcard-text2">
                                <span>Backend Developer</span>
                                </span>
                            </div>
                            </div>
                            <div className="testimonialcard-userreview">
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg" />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-empty.svg"  />
                                </div>
                            </div>
                        </div>
                        <div className="testimonialcard-reviewsection">
                            <span className="testimonialcard-text4">
                            <span>
                                Very good experience using the services from BeyondHire
                            </span>
                            </span>
                            <span className="testimonialcard-text6">
                            <span>
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat aute irure sint
                                amet occaecat cupidatat non proidents
                            </span>
                            </span>
                        </div>
                        </div>
                    </div>
            </div>   
            <div className="testimonialcard-container ">
                    <div className="testimonialcard-testimonialcard ">
                        <div className="testimonialcard-innercard">
                        <div className="testimonialcard-usersection">
                            <div className="testimonialcard-userinfo">
                                <div className="testimonialcard-user-avatar">
                                    <Image src="images/user-avatar.png" />
                                </div>
                            <div className="testimonialcard-username">
                                <span className="testimonialcard-text">
                                <span>Marge Simpson</span>
                                </span>
                                <span className="testimonialcard-text2">
                                <span>Backend Developer</span>
                                </span>
                            </div>
                            </div>
                            <div className="testimonialcard-userreview">
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg" />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-empty.svg"  />
                                </div>
                            </div>
                        </div>
                        <div className="testimonialcard-reviewsection">
                            <span className="testimonialcard-text4">
                            <span>
                                Very good experience using the services from BeyondHire
                            </span>
                            </span>
                            <span className="testimonialcard-text6">
                            <span>
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat aute irure sint
                                amet occaecat cupidatat non proidents
                            </span>
                            </span>
                        </div>
                        </div>
                    </div>
            </div>   
            <div className="testimonialcard-container ">
                    <div className="testimonialcard-testimonialcard ">
                        <div className="testimonialcard-innercard">
                        <div className="testimonialcard-usersection">
                            <div className="testimonialcard-userinfo">
                                <div className="testimonialcard-user-avatar">
                                    <Image src="images/user-avatar.png" />
                                </div>
                            <div className="testimonialcard-username">
                                <span className="testimonialcard-text">
                                <span>Marge Simpson</span>
                                </span>
                                <span className="testimonialcard-text2">
                                <span>Backend Developer</span>
                                </span>
                            </div>
                            </div>
                            <div className="testimonialcard-userreview">
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg" />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-empty.svg"  />
                                </div>
                            </div>
                        </div>
                        <div className="testimonialcard-reviewsection">
                            <span className="testimonialcard-text4">
                            <span>
                                Very good experience using the services from BeyondHire
                            </span>
                            </span>
                            <span className="testimonialcard-text6">
                            <span>
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat aute irure sint
                                amet occaecat cupidatat non proidents
                            </span>
                            </span>
                        </div>
                        </div>
                    </div>
            </div>   
            <div className="testimonialcard-container ">
                    <div className="testimonialcard-testimonialcard ">
                        <div className="testimonialcard-innercard">
                        <div className="testimonialcard-usersection">
                            <div className="testimonialcard-userinfo">
                                <div className="testimonialcard-user-avatar">
                                    <Image src="images/user-avatar.png" />
                                </div>
                            <div className="testimonialcard-username">
                                <span className="testimonialcard-text">
                                <span>Marge Simpson</span>
                                </span>
                                <span className="testimonialcard-text2">
                                <span>Backend Developer</span>
                                </span>
                            </div>
                            </div>
                            <div className="testimonialcard-userreview">
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg" />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-full.svg"  />
                                </div>
                                <div className="testimonialcard-vector">
                                    <Image src="images/star-empty.svg"  />
                                </div>
                            </div>
                        </div>
                        <div className="testimonialcard-reviewsection">
                            <span className="testimonialcard-text4">
                            <span>
                                Very good experience using the services from BeyondHire
                            </span>
                            </span>
                            <span className="testimonialcard-text6">
                            <span>
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                laboris nisi ut aliquip ex ea commodo consequat aute irure sint
                                amet occaecat cupidatat non proidents
                            </span>
                            </span>
                        </div>
                        </div>
                    </div>
            </div>   
             
            
        </OwlCarousel>
    </div>
  )
}

export default Testimonials