import React from 'react'
import Initial from './Initial'
import UserType from './UserType'
import Video from './Video'
import Testimonials from './Testimonials'
import NewConnections from './NewConnections'
import Partners from './Partners'

export const Index = () => {
  return (
    <div>
        <Initial />
        <UserType />
        <Video />
        <Testimonials />
        <NewConnections />
        <Partners />
    </div>
  )
}

export default Index;
