import React from "react";

// Library import
import { Container } from "react-bootstrap";

// Image import
import Image from "react-bootstrap/Image";

// Local css import
import "../../css/learn-page-jobseeker/interview-section.css";


const InterviewSection = () => {
  return (
    <div>
        <Container className="interview-section-container">
            <div className="row interview-demo-row flex-column-reverse flex-lg-row">
                {/* Interview prep image */}
                <div className="col-lg-5 image-section">
                    <Image src="/images/interview-dummy.svg" />
                </div>
                {/* Interview prep description */}
                <div className="col-lg-4 text-section">
                    <h2>Prepare for Interviews</h2>
                    <p>BeyondHire provides job seekers and students with a list of videos. 
                        These videos guide you to prepare for interviews and contains information 
                        about different dos and donts.</p>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default InterviewSection