import React from "react";

// Library import
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown"

// Image import
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/landing-page-css/initial.css";

function Initial() {
  return (
    <div>
      {/* Initial section */}
      <div className="starting-section">
        
        {/* Search area */}
        <div className="search-area">
          <form
            action="#"
            id="header-search"
            class="form-area"
            novalidate="novalidate"
            autocomplete="off"
          >
            <div className="row">
              {/* Job search fields */}
              <div className="col-md-10">
                <div className="styled-input wide multi">
                  <div className="job-title">
                    <input
                      type="text"
                      name="fn"
                      id="fn"
                      autocomplete="off"
                      data-placeholder-focus="false"
                      required
                    />
                    <label>Job Title</label>
                  </div>

                  <div className="location" id="input-first-name">
                    <input
                      type="text"
                      name="fn"
                      id="fn"
                      autocomplete="off"
                      data-placeholder-focus="false"
                      required
                    />
                    <label>Location</label>
                  </div>

                  <div className="keywords" id="input-first-name">
                    <input
                      type="text"
                      name="fn"
                      id="fn"
                      autocomplete="off"
                      data-placeholder-focus="false"
                      required
                    />
                    <label>Keywords <span>(Optional)</span></label>
                  </div>
                  
                </div>
              </div>

              {/* Job Search Button */}
              <div class="col-md-2 no-pad-left-10">
                <button
                  type="submit"
                  class="primary-btn serach-btn"
                  id="submit_btn"
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          {/* Job search filters */}
          <div className="row">
            <div className="all-dropdowns">      
              <Dropdown className="job-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  Date Posted
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-items">
                  <Dropdown.Item href="#/action-1" id="drop-item">Past 24 hours</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" id="drop-item">This week</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This month</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="job-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  Company
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-items">
                  <Dropdown.Item href="#/action-1" id="drop-item">Past 24 hours</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" id="drop-item">This week</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This month</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="job-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  Salary
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-items">
                  <Dropdown.Item href="#/action-1" id="drop-item">Past 24 hours</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" id="drop-item">This week</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This month</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="job-dropdown">
                <Dropdown.Toggle id="dropdown-basic">
                  Job Type
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-items">
                  <Dropdown.Item href="#/action-1" id="drop-item">Past 24 hours</Dropdown.Item>
                  <Dropdown.Item href="#/action-2" id="drop-item">This week</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This month</Dropdown.Item>
                  <Dropdown.Item href="#/action-3" id="drop-item">This year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Initial;


