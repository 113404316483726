import { BrowserRouter,Routes,Route} from "react-router-dom";
import Header from "./common/Header";
import SignUp from "./components/signup/SignUp";
import Landing from "./components/landing/Index"
import LearnMoreJobSeeker from "./components/learn-more-jobseeker/LearnIndex"
import BusinessAbout from "./components/business-about/BusinessIndex"
import Support from "./components/support/SupportIndex"


const App = () => {
  return (

    // basename="/beyond-hire"
    
    <BrowserRouter>
    
    <Routes>
    <Route path="/" element={<Landing />} />
    <Route path="learn-more-jobseeker" element={<LearnMoreJobSeeker />} />
    <Route path="business-about" element={<BusinessAbout />} />
    <Route path="support" element={<Support />} />
  
    {/* <Route path="support" element={<Support />} /> */}

  
      <Route path="signup" element={<Header/>} >
      
        <Route index element={<SignUp />} />

      </Route>

      <Route path="*" element={<p>Error</p>} />

   
    </Routes>
   
  </BrowserRouter>
  
  );
}

export default App;
