import React from 'react'

// Library import
import { Container } from 'react-bootstrap';

// Image import 
import Image from 'react-bootstrap/Image';

// Local css import
import "../../css/business-about/business-features.css";

const BusinessFeatures = () => {
  return (
    <div className='business-features'>
        {/* List of business features */}

        {/* Business Feature 1 */}
        <Container className="feature">
                <div className="row justify-content-md-center align-items-center feature-row 
                    flex-column-reverse 
                    flex-lg-row" 
                >                    
                    <div className="col-lg-8 image-section">
                        <Image src="/images/business-feature-1.png"/>
                    </div>
                    <div className="col-lg-4 text-section">
                        <h3>Find Candidates</h3>
                        <p>BeyondHire Provides an easy way for you to find the best candidates for you</p>
                    </div>
                </div>
            </Container>

        {/* Business Feature 2 */}
        <Container className="feature">
                <div className="row justify-content-md-center align-items-center feature-row">                                
                    <div className="col-lg-3 text-section">
                        <h3>Launch Pad for Advertisement</h3>
                        <p>Our launch pad gives you the opportunity to promote your company product 
                            to the entire BeyondHire platform</p>
                    </div>
                    <div className="col-lg-5 image-section">
                        <Image src="/images/business-feature-2.png"/>
                    </div>
                </div>
            </Container>

        {/* Business Feature 3 */}
        <Container className="feature feature-3">
                <div className="row justify-content-md-center align-items-center feature-row 
                    flex-column-reverse 
                    flex-lg-row" 
                >                    
                    <div className="col-lg-7 image-section">
                        <Image src="/images/business-feature-3.png"/>
                    </div>
                    <div className="col-lg-4 text-section">
                        <h3>Track the Progress of Candidates</h3>
                        <p>BeyondHire Provides a clean and sophisticated method to manage all of your candidates</p>
                    </div>
                </div>
            </Container>
    </div>
  )
}

export default BusinessFeatures